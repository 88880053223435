import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-steps',
  templateUrl: './header-steps.component.html',
  styleUrls: ['../steps.component.scss']
})
export class HeaderStepsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
