import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ThemePalette } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material';

@Component({
  selector: 'app-industry',
  templateUrl: './industry.component.html',
  styleUrls: ['../steps.component.scss']
})

export class IndustryComponent implements OnInit {

  formSteps: FormGroup;
  
  constructor(
    private formBuilder: FormBuilder, 
    private route: ActivatedRoute,
    private router: Router
    ) {
  }
  
  sub: any;
  page : any;
  businessName: string;

  //pagitation settings
  color: ThemePalette = 'primary';
  mode: MatProgressBarModule = 'determinate';
  value = 25;
  bufferValue = 100;
  //pagitation settings
  
  ngOnInit(){
    let valueItem = localStorage.getItem('industry');
    this.formSteps =  this.formBuilder.group({
      industry: [valueItem, [Validators.required]],
    })
    this.route.queryParams.subscribe(params => {
      this.businessName = params['business_name'];
    })
  }
  get form() { return this.formSteps.controls;}
  
  getFormValue(){
    
    this.sub = this.route
    .queryParams
    .subscribe(params => {
      this.page = this.formSteps.value.industry;
    });
    this.router.navigate(['/impacts-covid'], { queryParams: { "industry": this.page}, queryParamsHandling: "merge"});
    localStorage.setItem('industry', this.formSteps.value.industry);
      
  }

}
