import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-business-name',
  templateUrl: './business-name.component.html',
  styleUrls: ['../steps.component.scss']
})
export class BusinessNameComponent implements OnInit {

  formBusinessName: FormGroup;
  sub: any;
  page : any;
  activeClass: boolean;
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    ) {

    }
    
    ngOnInit() {
    let valueItem = localStorage.getItem('business_name');
    this.formBusinessName = this.formBuilder.group({
      business_name : [valueItem, Validators.required]
    })
  }
  get form() { return this.formBusinessName.controls;}
  
  getFormValue(){
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        this.page = this.formBusinessName.value.business_name
    });
    this.router.navigate(['/industry'], { queryParams: { "business_name": this.page}, queryParamsHandling: "merge" });
    localStorage.setItem('business_name', this.formBusinessName.value.business_name);
  }

}
